import Vue from "vue";
import axios from "axios";
const isProd = process.env.NODE_ENV === "production";
export const APIHOST = isProd
  ? "https://fc-resource-node-api.krzb.net"
  : "http://localhost:20030";

export const fillHost = (url) => `${APIHOST}${url}`;

export const request = async ({
  method = "GET",
  data,
  url,
  params = {},
  headers = {},
  isPrefix = true,
}) => {
  try {
    const res = await axios({
      method,
      url: isPrefix ? `${APIHOST}${url}` : url,
      data,
      timeout: 20 * 1000,
      params: {
        t: new Date().getTime(),
        version: "v2",
        ...params,
      },
      headers: {
        ...headers,
      },
      withCredentials: isPrefix ? true : false,
    });
    return res.data;
  } catch (err) {
    console.log(`request error: ${err}`);
    return null;
  }
};

/**
 * 浏览器对于同域名下的请求，有并发数小于 5 的限制，当并发数超过限制时，请求会被阻塞
 * 当检测列表页时应该使用此方法，以便同时检测所有链接。response为chunked类型，每次输出一行json。  先检测成功的请求会先返回结果
 * @param {Promise<Response>} respPromise
 * @param {(any) => void} cb
 * @returns Promise<void>
 */
export const fetchJsonLines = async (respPromise, cb) => {
  cb = cb || (() => {});
  const objList = [];
  try {
    const response = await respPromise;
    const reader = response.body.getReader();
    let utf8Decoder = new TextDecoder();
    let buffer = "";

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }

      buffer += utf8Decoder.decode(value, { stream: true });

      const lines = buffer.split("\n");
      buffer = lines.pop(); // Keep the incomplete line in the buffer

      for (const line of lines) {
        if (line.trim() !== "") {
          try {
            const obj = JSON.parse(line);
            objList.push(obj);
            cb(obj);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }
      }
    }

    // Process any remaining data in the buffer after the stream has ended
    if (buffer.trim() !== "") {
      try {
        const obj = JSON.parse(buffer);
        objList.push(obj);
        cb(obj);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return objList;
};

const testFetchJsonLines = async () => {
  const eu = [
    "l0o4ahQ9lTn7eptVnyUd5H3oslB35tAZrHGgEcgkpUpVkMLJdX3x4nl28l9RJ3AxoJK6S_t9GBvbJ_IY4Oz0jg==",
    "6XgJht_3j9nZCowhDdsf23bYYAJJ9NZZ4_3pHblc0FPGamhKMLroY6QLX2oJssiVo45dqjZpz-Jhsk0Fnhe5bQ==",
    "3zKvNL9qa6yz8W-VVrzD_VEc_65O41CjzjHRhs20KSjVYcHKNYxrtnw87NgiI0cob--NGEjShy1Big8C85Uo6Q==",
    "ITyaPqWPR5u-4WPmcJVX6iQPDsSQxYf_lubzsPgS0TsbJwg3KYFDkZCArW4PRrsxZEc5xqEnlYU7crKkJx6HjQ==",
    "VQE8MYfmj4iObIoaXqOhxlKNp35Pk-E2DgyhDDJmbUTCvrKPtllUnpc8K-72UaS5aXAMbXFGuP_XnY3E67ZwZQ==",
    "YKBw7ztUWTK3uIlWmOSM3bZN-TslbWohdipFqPLqkfrotQrZErADgKdviTQ5Vfp_bYxzrGbYUyxxKWTiHD-LOQ==",
    "fn8OUVQciMItmuUkilEIKGFP3K-7G-7Xnz32P8cGZ2j91_pxFI8Lx-ev-WHmF1Oko7NB2BLWgxjnT-qEINoPXA==",
    "9A0XZo8bgsxP7cSzgqYeGju7s17oGFEtrWBzGJUpUgy3qmtwMSZOTEf1o9ok8dhPnT4VOhjRnmR0L0782C5PWg==",
    "vbwKldyF8TcASa3MZhNdHgR48af2gBznitihAenC9Fmkzd6tItK1nxtxbkLaWOE54xTylUINLX8xO7aorA_fzg==",
    "jwKY_Kzw-A79DO-7LJnv9mJVQw-ax_jfo4fWrqYBHOXBs-uaovi8PYKyz_gnZTg2zNWd-QwkFPtdBR5qcxRagg==",
    "S1l_UvoglGmE5Vh26zbNyxOtPaUHJOzSprhxZzxGiSncm3sjkJAkRfgc58KE0s5CTkMPCThOOPL9SZwIVeufEg==",
    "jTy2X5eZU-oGvA8pNjyt5X_tTyj8CSIqEFG7ijXKQ8tGpmDcdnyjAUk5X1Zy9sE3oY7CUH06-PLorxboKfGncg==",
    "TRxannuUg-muLwuSNFESS5JnbWslNVfMJS0OJLenHKsxNtua7sMi-X4XLkxKnnIIjJFjSriJAT1Zh04Y2JN4Tw==",
    "4usGGZF85eHqr4KpebjxhjofFT4ORpCbAFSowZroyloeYVmM5GydZ8UafV2aqlVDrXzC6omne1zvAtU-kkqrOg==",
    "oO27r5EWN1zjOzIwb01uJYFQiExPZB4AgeyH3wVDT-WOQcE1Bzfc2Ppp9zil-w_L9cFuWgaibUwLDQsrIy-goA==",
  ];
  const cb = (obj) => {
    console.log(new Date(), obj);
  };
  return fetchJsonLines(
    fetch(`https://fc-resource-node-api.krzb.net/api/v1/pan/validShareLink`, {
      method: "POST",
      body: JSON.stringify({ eus: eu, cacheTtl: 1, realtime: false }), // cacheTtl 仅用于测试，不要在生产环境传此参数； realtime 是否实时检测，列表页检测时应传 false，详情页检测时应传 true（false 取 1小时内缓存结果，true取5分钟内缓存结果）
      headers: {
        "Content-Type": "application/json",
        "X-Authorization":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlvbmlkIjoib1RJWk02RUxOak1oVVhaRTByVUdXeGRIcWJzOCIsImlhdCI6MTcxNjYwODU5MSwiZXhwIjoxNzE5MjAwNTkxfQ.K1Aet4VSHEIscV3Bi6miU_3As1b-_b_5nHyJQDo4rsc",
      },
    }),
    cb
  );
};

/**
 * result : [[2,1],[7,1],[6,1],[10,1],[3,1],[9,1],[13,1],[5,1],[14,1],[1,1],[4,1],[12,1],[0,1],[8,1],[11,1]]
 * 提交了几个检测请求，就输出几次结果（chunked json lines）。每行是一个json对象，格式为 [index, status]，index 是提交的顺序，status 是检测结果，1 为有效，0 为无效，-1 为未知
 */

// const result = await testFetchJsonLines()

// 响应式状态，用于ui组件自动更新
export const reactiveJsonLines = (eus, realtime, token) => {
  // import vue first
  const vm = new Vue({
    data: () => Object.fromEntries(eus.map((_, i) => [i, -2])), // -2 为未检测状态
  });

  const promise = fetchJsonLines(
    fetch(`https://fc-resource-node-api.krzb.net/api/v1/pan/validShareLink`, {
      method: "POST",
      body: JSON.stringify({ eus, cacheTtl: 1, realtime: false }), // cacheTtl 仅用于测试，不要在生产环境传此参数； realtime 是否实时检测，列表页检测时应传 false，详情页检测时应传 true（false 取 1小时内缓存结果，true取5分钟内缓存结果）
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": token,
      },
    }),
    (obj) => {
      vm.$set(vm, obj[0], obj[1]);
    }
  );

  return Object.assign(vm, { promise });
};

/**

const stautsMap = reactiveJsonLines(eus, realtime, token)

// 在模板中使用, 0 时隐藏。 其他状态显示检测文案
<div v-for="(item, idx) in list" :key="index" v-if="stautsMap[idx]!==0">

 */
