<template>
  <footer class="footer">
    <div class="copyright-wrap">
      <p>
        备用站点
        <a :href="getBeiYongDomain()" class="em theme" target="_blank">{{
          getBeiYongDomain()
        }}</a>
        <template v-if="getSelfBeiYongDomain()">
          ，<a
            :href="getSelfBeiYongDomain()"
            class="em theme"
            target="_blank"
            >{{ getSelfBeiYongDomain() }}</a
          >
        </template>
      </p>
      <p>
        @2014 - {{ year }} -
        <router-link to="/main/copyright">免责声明</router-link> -
        <a
          href="http://alibabachanpinjingliwenjuan.mikecrm.com/roQMiFa"
          target="_blank"
          >资源举报</a
        >
        -
        <a
          href="http://alibabachanpinjingliwenjuan.mikecrm.com/YLPBx6a"
          target="_blank"
          >建议反馈</a
        >
      </p>
      <p>
        以上内容由网络爬虫自动抓取，以非人工方式自动生成。<br />本站不储存、复制、传播任何文件，其网盘资源文件的完整性需要您自行判断。
      </p>
    </div>
  </footer>
</template>
<script>
import { PROJECT_NAME, DOMAIN } from "../constants";
import { getFriendLinks } from "../api/search";
export default {
  data() {
    return {
      links: [],
      year: new Date().getFullYear(),
      project_name: PROJECT_NAME,
      domain: DOMAIN,
    };
  },
  async mounted() {
    this.links = await getFriendLinks();
  },
};
</script>
<style lang="scss" scoped>
.copyright-wrap {
  font-size: 12px;
  color: #666;
  text-align: center;
  padding: 15px;

  p {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .em {
    font-weight: bold;
  }

  a {
    color: inherit;
  }
}
</style>
