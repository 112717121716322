<template>
  <van-dialog class="agree-dialog" v-model="show" :show-confirm-button="false">
    <div class="content">
      <h3>用户服务协议及相关政策声明</h3>
      <div class="procotol-wrap">
        <p>亲爱的用户:</p>
        <p>
          为保障网站&用户权益，请您仔细阅读<a
            href="/#/main/about/agree"
            target="_blank"
            >《用户服务协议》</a
          >的条款内容。您点击「同意」，即表示您已充分阅读、理解且接受前述协议的全部条款内容。特别如下内容:
        </p>
        <ol>
          <li class="em">
            您同意本网站不属于对百度网盘等网盘资源分享链接进行选择、编辑和推荐，非通过售卖网站内部会员获取直接经济收益性质的网站。
          </li>
          <li class="em">
            您有义务和接受：若您发现搜索到的爬虫内容涉及到侵权内容，请予第一时间通过本站发送到电子邮件：shakanamo945@gmail.com通知本站管理人员。
          </li>
        </ol>
      </div>
    </div>
    <div class="button-wrap">
      <van-button type="default" @click="hidePayAgreeDialog">不同意</van-button>
      <van-button type="default" class="confirm-button" @click="agree"
        >我同意</van-button
      >
    </div>
  </van-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      radio: 0,
    };
  },
  destroy() {},
  methods: {
    agree() {
      this.hidePayAgreeDialog();
      this.$store.commit("setPayDialog", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.agree-dialog {
  width: 380px;
  top: 40%;
  .content {
    color: #666;
    font-size: 12px;
    line-height: 1.5;
    padding: 20px;
    text-align: justify;
    h3 {
      color: #333;
      text-align: center;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 8px;
    }
    .em {
      color: #333;
    }
    ol {
      list-style: decimal;
      padding-left: 25px;
      li {
        margin-top: 8px;
      }
    }
    .procotol-wrap {
      max-height: 300px;
      overflow-y: auto;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: space-between;
    .van-button {
      width: 50%;
      height: 50px;
      font-size: 15px;
    }
    .confirm-button {
      border-left: none;
      color: $theme-color;
    }
  }
  .radio-header {
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;
  }
  .van-radio-group {
    margin-bottom: 12px;
    color: #333;
    .van-radio {
      margin-top: 6px;
    }
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    bottom: -46px;
    left: 50%;
    margin-left: -18px;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    background-color: rgba(#000, 0.7);
    &:hover {
      cursor: pointer;
      background-color: #000;
    }
  }
}
@media (max-width: 700px) {
  .agree-dialog {
    width: 90%;
    top: 45%;
    .content {
      padding: 15px;
      .procotol-wrap {
        max-height: 240px;
        overflow-y: auto;
      }
    }
  }
}
</style>
