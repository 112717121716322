<template>
  <div id="app" @click="hideFilter">
    <router-view :key="$router.currentRoute.fullPath" />
    <login-dialog-x v-if="loginDialogShow" />
    <LoginByEmail v-if="loginEmailDialogShow" />
    <BindUser v-if="bindUserDialog" />
    <LoginAgree v-if="loginAgreeDialog" />
    <PayAgree v-if="payAgreeDialog" />
    <pay-dialog v-if="payDialogShow" />
    <footer-component v-if="showFooter" />
    <Feedback />
  </div>
</template>

<script>
import Feedback from "./components/Feedback.vue";
import PayDialog from "./components/Pay.vue";
import LoginDialogX from "./components/LoginX.vue";
import PayAgree from "./components/PayAgree.vue";
import LoginAgree from "./components/LoginAgree.vue";
import BindUser from "./components/BindUser.vue";
import LoginByEmail from "./components/LoginByEmail.vue";
import FooterComponent from "./components/Footer";
import copyToClipboard from "./utils/clipboard";
import { getCopyContent } from "./api/search";
export default {
  data() {
    return {
      url: "",
      content: "",
      isCopy: false,
      showFooter: false,
    };
  },
  watch: {
    $route: function(to) {
      if (!["login", "pay"].includes(to.name)) {
        this.showFooter = true;
      }
    },
  },
  components: {
    Feedback,
    PayAgree,
    LoginAgree,
    PayDialog,
    LoginDialogX,
    LoginByEmail,
    BindUser,
    FooterComponent,
  },
  async mounted() {
    const inviteCode = this.$route.query.code;
    if (inviteCode) localStorage.setItem("inviteCode", inviteCode);
    const res = await getCopyContent();
    if (res && res.isCopy) {
      this.isCopy = res.isCopy;
      this.content = res.content;
    }
    this.checkWebsite();
  },
  methods: {
    hideFilter() {
      this.$store.commit("hideFilter");
      if (this.isCopy && this.isMobile) {
        copyToClipboard(this.content);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_base.scss";
#app {
  height: 100%;
}
</style>
