import Vue from "vue";
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import { APIHOST } from "@/api/_base";
import { getUserinfo, getPayPackages } from "@/api/user";
import { PROJECT_NAME, DOMAIN, WAYS } from "@/constants";
import { isWeixin, isMobile, isIOS, isAndroid } from "@/utils/navigator";
Vue.mixin({
  data() {
    return {
      WAYS,
      DOMAIN,
      APIHOST,
      PROJECT_NAME,
      isIOS: isIOS(),
      isWeixin: isWeixin(),
      isMobile: isMobile(),
      isAndroid: isAndroid(),
      bbsSites: [
        "https://yimiaoxia.com",
        "https://wangpan.website",
        "https://wpzys.online",
        "https://yunziyuan.cloud",
      ],
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "userinfo",
      "paypackages",
      "payDialogShow",
      "loginDialogShow",
      "loginEmailDialogShow",
      "bindUserDialog",
      "loginAgreeDialog",
      "payAgreeDialog",
      "searchWay",
      "loginAgreeType",
    ]),
    testing() {
      return ["localhost", "127.0.0.1", "test."].some(
        (x) => location.host.indexOf(x) > -1
      );
    },
  },
  methods: {
    async checkWebsite() {
      const checkUrl = async (url) => {
        try {
          await axios.get(url, {
            timeout: 3000,
          });
          return {
            url,
            status: true,
          };
        } catch (err) {
          return {
            url,
            status: true,
          };
        }
      };
      const promises = this.bbsSites
        .filter(
          (item) => !["true", "false"].includes(localStorage.getItem(item))
        )
        .map((item) => checkUrl(item));
      const results = await Promise.all(promises);
      for (let i = 0; i < results.length; i++) {
        const { url, status } = results[i];
        localStorage.setItem(url, status);
      }
    },
    getBeiYongDomain() {
      return "https://www.feizhupan.com";
    },
    getSelfBeiYongDomain() {
      const origin = window.location.origin
      if (origin.includes('.com')) return origin.replace('.com', '.xyz');
      if (origin.includes('.xyz')) return origin.replace('.xyz', '.com');
      return ''
    },
    openWindow(url, target = false) {
      const obj = document.createElement("a");
      if (target) {
        obj.target = "_blank";
      }
      obj.referrerPolicy = "origin";
      obj.href = url;
      obj.click();
    },
    async updateUserinfo() {
      const res = await getUserinfo();
      if (res && res.code == 0) {
        this.$store.commit("setLogin", true);
        this.$store.commit("setUserinfo", res.data);
        localStorage.setItem("email", res.data.email || "");
      } else if (res && res.code == 1001) {
        this.$store.commit("setLogin", false);
        localStorage.setItem("token", "");
      }
    },
    async updatePayPackages() {
      const res = await getPayPackages();
      this.$store.commit("setPayPackages", res);
    },
    showPayDialog() {
      // this.showPayAgreeDialog();
      this.$store.commit("setPayDialog", true);
    },
    hidePayDialog() {
      this.$store.commit("setPayDialog", false);
    },
    showLoginDialog() {
      this.showLoginAgreeDialog();
      // this.$store.commit("setLoginEmailDialog", true);
    },
    hideLoginDialog() {
      this.$store.commit("setLoginEmailDialog", false);
    },
    showPayAgreeDialog() {
      this.$store.commit("setPayAgreeDialog", true);
    },
    hidePayAgreeDialog() {
      this.$store.commit("setPayAgreeDialog", false);
    },
    showLoginAgreeDialog() {
      this.$store.commit("setLoginAgreeDialog", true);
    },
    hideLoginAgreeDialog() {
      this.$store.commit("setLoginAgreeDialog", false);
    },
    showBindUserDialog() {
      this.$store.commit("setBindUserDialog", true);
    },
    hideBindUserDialog() {
      this.$store.commit("setBindUserDialog", false);
    },
    setLoginAgreeType(data) {
      this.$store.commit("setLoginAgreeType", data);
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    formatTime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    bytesToSize(bytes) {
      if (bytes < 0) {
        return "0KB";
      }
      const b = parseInt(bytes, 10);
      const sizes = ["B", "KB", "MB", "GB", "TB"];
      if (isNaN(b)) {
        return "0KB";
      }
      if (b === 0) {
        return "0KB";
      }
      const i = parseInt(Math.floor(Math.log(b) / Math.log(1024)), 10);
      if (i === 0) {
        return `${b}${sizes[i]}`;
      }
      if (i > 1) {
        return `${(b / 1024 ** i).toFixed(1)}${sizes[i]}`;
      } else {
        return `${(b / 1024 ** i).toFixed(0)}${sizes[i]}`;
      }
    },
  },
});
