export default (content) => {
  const input = document.createElement('input')
  input.setAttribute('readonly', 'readonly')
  input.setAttribute('value', content)
  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, 9999) // 兼容移动端
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    document.body.removeChild(input)
    return true
  } else {
    document.body.removeChild(input)
    return false
  }
}
