<template>
  <van-dialog class="pc-dialog" v-model="mask" :before-close="closeMask" confirm-button-text="取消" confirm-button-color="black">
    <div class="content">
       <h3>绑定之前注册的微信账号</h3>
      <img :src="qrcode" class="qrcode" alt="微信扫码绑定">
      <p class="tip" v-if="!isWeixin && state == 'init'">微信扫码绑定</p>
      <p class="tip" v-if="!isWeixin && state == 'scanned'">已扫码，请确认绑定</p>
    </div>
  </van-dialog>
</template>
<script>
import { qrcodeInit, qrcodeState } from "@/api/login"
import { bindUser } from "@/api/user"

export default {
  data() {
    return {
      qrcode: '',
      mask: true,
      state: 'init',
      cancel: false,
    }
  },
  created() {
    setTimeout(() => this.mask && this.closeMask(), 300e3) // auto close after 5min
    this.login()
        .then(() => !this.cancel && this.$toast('绑定成功'))
        .catch(async () => {
          if (!this.cancel) {
            try {
              const state = await qrcodeState(cancelFn => this.$cancelPoll = cancelFn) // long polling, timeout 60s
              if (state.State === 'finished') {
                const inviteCode = this.$route.query.code
                if (inviteCode) {
                  state.Data.inviteCode = inviteCode
                }
                const { data, message } = await bindUser(state.Data)
                  if (!data && message) {
                    this.$toast(message)
                    this.closeMask()
                  } else { 
                    this.$toast.success('绑定成功')
                    setTimeout(() => { 
                      localStorage.setItem('token', data.token)
                      location.reload()
                    }, 1000)
                  }
              } else if (state.State == 'scanned') {
                this.state = state.State
              }
            } catch (err) {
              this.$toast(`${err.message}`)
            }
          }
        })
        .finally(() => this.hideBindUserDialog())
  },
  methods: {
    async login() {
      this.qrcode = (await qrcodeInit()).Qrcode
      while (!this.cancel) {
        const minInterval = new Promise(r => setTimeout(r, 2e3)) // sleep when immediate failure
        const state = await qrcodeState(cancelFn => this.$cancelPoll = cancelFn) // long polling, timeout 60s
        if (state.State === 'finished') {
          const inviteCode = this.$route.query.code
          if (inviteCode) {
            state.Data.inviteCode = inviteCode
          }
          const { data, message } = await bindUser(state.Data)
          if (!data && message) {
            this.$toast(message)
            this.closeMask()
          } else { 
            this.$toast.success('绑定成功')
            setTimeout(() => { 
              localStorage.setItem('token', data.token)
              location.reload()
            }, 1000)
          }
          return
        } else if (state.State == 'scanned') {
          this.state = state.State
        }
        await minInterval
      }
    },
    closeMask(_, done) {
      this.hideBindUserDialog()
      this.cancel = true
      this.$cancelPoll && this.$cancelPoll()
      done && done()
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.van-dialog {
  .content {
    text-align: center;
    padding: 20px 20px 10px 20px;
  }
  .qrcode {
    width: 200px;
    height: 200px;
  }
  .tip {
    font-size: 13px;
    color: #666;
    margin-top: 5px;
    &.logout {
      margin-top: 15px;
    }
    a {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .intro {
    ul {
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: #f2f2f2;
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
      color: #666;
    }
  }
  &.info-dialog {
    .vipinfo {
      margin: 15px 0;
      padding: 10px;
      border-radius: 4px;
      background-color: rgba($theme-color, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      text-align: left;
      .em {
        margin-bottom: 5px;
      }
      .van-button {
        padding: 0 10px;
      }
    }
  }
}
</style>
