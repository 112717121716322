import { request } from "@/api/_base";

export const getUserinfo = async () => {
  return await request({
    url: `/api/v1/user/info`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getUrl = async (id) => {
  return await request({
    url: `/api/v1/pan/url`,
    params: { id },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const logout = async () => {
  return await request({
    url: `/api/v1/logout`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getPayPackages = async () => {
  return await request({
    url: `/api/v1/packages`,
  });
};

export const loginSignedUser = async (data) => {
  return await request({
    method: "post",
    url: `/api/v1/login_signed_user`,
    data,
  });
};

export const bindUser = async (data) => {
  return await request({
    data,
    method: "post",
    url: `/api/v1/user/bind`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const signWeek = async () => {
  return await request({
    url: `/api/v1/sign/week`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const unblockUser = async (uid) => {
  return await request({
    url: `/api/v1/user/unblock`,
    params: {
      uid,
    },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const refundUser = async (uid) => {
  return await request({
    url: `/api/v1/user/refund`,
    params: {
      uid,
    },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getHostOverviewData = async () => {
  return await request({
    url: "/api/v1/invite/overview",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getHostRecentData = async () => {
  return await request({
    url: "/api/v1/invite/recent",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getAdminHostOverviewData = async () => {
  return await request({
    url: "/api/v1/admin/invite/overview",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getAdminHostRecentData = async () => {
  return await request({
    url: "/api/v1/admin/invite/recent",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const withdraw = async ({ amount }) => {
  return await request({
    method: "POST",
    url: "/api/v1/withdraw",
    data: {
      amount,
    },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const updateWithdrawAccount = async ({ alipayaccount, alipayname }) => {
  return await request({
    method: "POST",
    url: "/api/v1/withdraw/update",
    data: {
      alipayaccount,
      alipayname,
    },
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getWithdraws = async () => {
  return await request({
    url: "/api/v1/withdraw/history",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const submitResources = async (data) => {
  return await request({
    method: "POST",
    url: "/api/v1/pan/submitResource",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
    data: {
      data,
    },
  });
};

export const applyUnblock = async () => {
  return await request({
    method: "POST",
    url: "/api/v1/unblock/apply",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const getBlockList = async () => {
  return await request({
    method: "GET",
    url: "/api/v1/apply/list",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};

export const updateBlockStatus = async (data) => {
  return await request({
    method: "POST",
    url: "/api/v1/apply/update",
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
    data: {
      ...data,
    },
  });
};
