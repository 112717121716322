<template>
  <van-dialog class="login-dialog" v-model="show" :show-confirm-button="false">
    <div class="content">
      <div class="panel login-panel">
        <h3>登录/注册</h3>
        <p class="login-tip">如果您之前尚未注册过，登录成功即会帮您注册账户</p>
        <el-input
          placeholder="请填写真实邮箱地址"
          v-model.trim="email"
          @keyup.enter.native="register"
        >
          <template slot="prepend">邮箱地址</template>
        </el-input>
        <el-input
          class="dark"
          placeholder="邮箱验证码"
          v-model.trim="emailcode"
          @keyup.enter.native="register"
        >
          <template slot="append">
            <el-button
              type="text"
              :disabled="leftSecond > 0"
              @click="handleSendCode"
              :style="{
                color: leftSecond > 0 ? '#909399' : '#409eff',
              }"
              >{{
                leftSecond > 0 ? `${leftSecond}秒后重新获取` : "获取邮箱验证码"
              }}</el-button
            >
          </template>
        </el-input>
        <van-button
          type="primary"
          size="normal"
          block
          @click="login"
          :loading="loading"
          :disabled="!emailcode || !email"
          >登录</van-button
        >
        <p class="tip">
          登录成功即表示您已同意本站用户协议
          <a href="/#/main/about/agree" target="_blank">《用户协议》</a>
        </p>
      </div>
      <p style="font-size: 12px; margin-top: 10px; text-align: center;">
        客服QQ：3556044645
      </p>
      <p class="warn-tip">
        注意📢：网站改版，现更改为邮箱登录，原先微信注册的账号，可以用邮箱重新注册账号之后，在「右上角」-「个人中心」绑定原有的账户。
        <br /><span style="font-weight: bold; padding-top: 5px;"
          >收不到邮件的请查看是否在邮箱的垃圾箱。</span
        >
      </p>
      <van-icon class="close" name="cross" @click="hideLoginDialog" />
    </div>
  </van-dialog>
</template>

<script>
import { loginByEmail, sendEmailCode } from "@/api/loginByEmail.js";
import { isValidEmail } from "@/utils/validate.js";

export default {
  data() {
    return {
      show: true,
      email: localStorage.getItem("email") || "",
      emailcode: "",
      loading: false,
      timer: null,
      leftSecond: 0,
    };
  },
  destroy() {
    this.leftSecond = 0;
    clearInterval(this.timer);
  },
  methods: {
    async login() {
      if (!this.email || !this.emailcode) {
        return this.$toast.fail("请填写邮箱、密码和验证码");
      }
      const res = await loginByEmail({
        email: this.email,
        emailcode: this.emailcode,
        agreetype: this.loginAgreeType,
        inviteCode:
          this.$route.query.code || localStorage.getItem("inviteCode"),
      });
      if (res.code == 0) {
        this.$toast.success("登录成功");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("email", this.email);
        location.reload();
      } else {
        return this.$toast.fail(res.message);
      }
    },
    handleCalLeftSecond() {
      this.leftSecond = 60;
      this.timer = setInterval(() => {
        if (this.leftSecond === 1) {
          clearInterval(this.timer);
        }
        this.leftSecond -= 1;
      }, 1000);
    },
    async handleSendCode() {
      if (!isValidEmail(this.email)) {
        return this.$toast.fail("请输入正确的邮箱地址");
      }
      sendEmailCode({ email: this.email }).then((res) => {
        if (res.code === 0) {
          this.handleCalLeftSecond();
          if (res.message) this.$toast.success(res.message);
        } else {
          return this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
$input-height: 44px;

.login-dialog {
  width: 380px;
  top: 40%;
}
.warn-tip {
  margin-top: 10px;
  padding: 5px;
  color: rgb(128, 82, 50);
  font-size: 12px;
  border-radius: 2px;
  background-color: #fffbe8;
  border: 1px solid rgba(73, 33, 5, 0.2);
}
.content {
  padding: 5px 30px 40px;
  position: relative;
  .panel {
    padding-top: 15px;
    .el-input {
      margin-bottom: 15px;
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .button-wrap {
      display: flex;
      justify-content: space-between;
      .van-button {
        width: 45%;
      }
    }
    .tip,
    .login-tip {
      font-size: 12px;
      margin-top: 10px;
      color: #999;
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    .login-tip {
      margin-bottom: 10px;
    }
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    bottom: -46px;
    left: 50%;
    margin-left: -18px;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    background-color: rgba(#000, 0.7);
    &:hover {
      cursor: pointer;
      background-color: #000;
    }
  }
}
.van-dialog {
  overflow: visible;
}

@media (max-width: 700px) {
  .login-dialog {
    width: 90%;
    top: 45%;
    .content {
      padding: 5px 20px 20px;
    }
  }
}
</style>
