import axios from "axios";

export const decorateAxios = (service) => {
  service.interceptors.request.use(
    (config) => config,
    (error) => {
      // do something with request error
      console.log(error); // for debug
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    (response) => {
      const res = response.data;
      if (res.code !== 0) {
        return Promise.reject(new Error(res.message || "Error"));
      } else {
        return res;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return service;
};

const request = decorateAxios(
  axios.create({
    baseURL: "https://sousuoxia-wxmp.hexiaotu.com/wx_mp_login",

    timeout: 65e3, // request timeout
  })
);

export const qrcodeInit = async () => {
  const params = {
    id: localStorage.getItem("login-qrcode-id"),
    user_id: localStorage.getItem("login-qrcode-user-id"),
  };
  const data = await request({
    url: "init",
    method: "get",
    params,
  }).then((data) => data.data);
  localStorage.setItem("login-qrcode-id", data.Id);
  localStorage.setItem("login-qrcode-state", "wait-scan");
  return data;
};

export const qrcodeState = async (useCancel) => {
  const source = axios.CancelToken.source();
  useCancel && useCancel(() => source.cancel("user cancel"));

  const params = {
    id: localStorage.getItem("login-qrcode-id"),
    state: localStorage.getItem("login-qrcode-state"),
  };
  const data = await request({
    url: "state",
    method: "get",
    params,
    cancelToken: source.token,
  }).then((data) => data.data);
  localStorage.setItem("login-qrcode-state", data.State);
  if (data.Data && data.Data.UserInfo) {
    localStorage.setItem(
      "login-qrcode-user-id",
      JSON.parse(data.Data.UserInfo).UserId
    );
  }
  return data;
};
