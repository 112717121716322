import { request } from "@/api/_base";

export const sendEmailCode = async ({ email }) => {
  return await request({
    method: "POST",
    url: `/api/v1/send_email`,
    data: {
      email,
    },
  });
};

export const loginByEmail = async ({
  email,
  emailcode,
  agreetype,
  inviteCode,
}) => {
  return await request({
    method: "POST",
    url: `/api/v1/login_by_email`,
    data: {
      email,
      emailcode,
      agreetype,
      inviteCode,
    },
  });
};

export const registerByEmail = async ({ email, emailcode, inviteCode }) => {
  return await request({
    method: "POST",
    url: `/api/v1/register_by_email`,
    data: {
      email,
      emailcode,
      inviteCode,
    },
  });
};

export const getGlobalEmailCode = async () => {
  return await request({
    url: `/api/v1/global_email_code`,
    headers: {
      "X-Authorization": localStorage.getItem("token") || "",
    },
  });
};
