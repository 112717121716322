import { request } from '@/api/_base'

export const getPayPackages = async () => {
  return await request({
    url: `/api/v1/packages`
  })
}

export const createOrder = async ({ pid, plt, paytype }) => {
  return await request({
    url: '/api/v1/order/create',
    params: {
      pid, plt, paytype
    },
    headers: {
      'X-Authorization': localStorage.getItem('token') || ''
    }
  })
}

export const queryOrder = async ({ order_id, type }) => {
  return await request({
    url: '/api/v1/order/query',
    params: {
      type,
      order_id
    },
    headers: {
      'X-Authorization': localStorage.getItem('token') || ''
    }
  })
}

export const refundOrder = async ({ order_id }) => {
  return await request({
    method: 'POST',
    url: '/api/v1/order/refund',
    data: {
      order_id
    },
    headers: {
      'X-Authorization': localStorage.getItem('token') || ''
    }
  })
}

export const payByCard = async (id) => {
  return await request({
    url: '/api/v1/paycard/use',
    params: {
      id
    },
    headers: {
      'X-Authorization': localStorage.getItem('token') || ''
    }
  })
}